<template>
  <div class="p-20 bg-white" style="min-height: 810px">
    <a-select v-model="filter" @change="jumpPage(1)">
      <a-select-option value="all">全部</a-select-option>
      <a-select-option value="reply">已回复</a-select-option>
      <a-select-option value="noreply">待回复</a-select-option>
    </a-select>

    <div v-if="comments.length">
      <div
        class="mt-20 hover:shadow"
        v-for="item in comments"
        :key="item.id"
        @click="open(item)"
      >
        <comment-card :dataSource="item">
          <a-space slot="opt" v-if="role !== 2">
            <a-popconfirm
              title="是否删除此提问"
              @confirm="delComment(item)"
              ok-text="删除"
              cancel-text="取消"
            >
              <a-icon @click.stop="" type="delete" />
            </a-popconfirm>
          </a-space>
          <div class="flex items-center pt-20" v-if="role === 2">
            <div
              style="width: 24px; height: 24px"
              class="rounded-full mr-10 border border-eee"
            >
              <img
                v-if="item.myAnswer"
                class="w-full h-full object-contain"
                :src="item.myAnswer.userLogo.url"
                alt=""
              />
              <img v-else src="@/assets/icons/question.svg" alt="" />
            </div>
            <div
              v-if="item.myAnswer"
              style="width: calc(100% - 34px)"
              class="ellipsis-2 text-sm"
            >
              {{ item.myAnswer.content }}
            </div>
            <div
              v-else
              style="width: calc(100% - 34px)"
              class="ellipsis-2 text-sm text-999"
            >
              <div v-if="item.inputing" class="flex">
                <a-form-model :model="model" style="width: calc(100% - 120px)">
                  <gf-textarea
                    v-focus
                    :rows="3"
                    :max="300"
                    placeholder="请在此输入您的回复"
                    @click.stop=""
                    v-model="model.Content"
                  ></gf-textarea>
                </a-form-model>
                <div
                  @click.stop="answerComment(item.id)"
                  class="
                    text-right text-white
                    flex
                    justify-center
                    items-center
                    bg-primary
                  "
                  style="width: 120px"
                >
                  确定
                </div>
              </div>
              <a-input
                v-else
                placeholder="请在此输入您的回复"
                @click.stop="$set(item, 'inputing', true)"
              ></a-input>
            </div>
          </div>
          <div class="flex items-center pt-20" v-else>
            <div
              style="width: 24px; height: 24px"
              class="rounded-full mr-10 border border-eee"
            >
              <img
                v-if="item.answers.length"
                class="w-full h-full object-contain"
                :src="item.answers[0].userLogo.url"
                alt=""
              />
              <img v-else src="@/assets/icons/question.svg" alt="" />
            </div>
            <div
              style="width: calc(100% - 34px)"
              class="ellipsis-2 text-sm text-999"
            >
              {{
                item.answers.length
                  ? item.answers[0].content
                  : "暂未收到专家回复"
              }}
            </div>
          </div>
        </comment-card>
      </div>
    </div>

    <list-empty v-else></list-empty>

    <div v-if="comments.length" class="text-center py-20">
      <a-pagination
        :pageSize="limit"
        v-model="page"
        :total="total"
        @change="jumpPage"
      ></a-pagination>
    </div>

    <a-drawer
      title="提问详情"
      placement="right"
      width="600"
      :visible="visible"
      @close="visible = false"
    >
      <div class="p-20 bg-f9">
        <div class="flex">
          <div
            class="rounded mr-20 border border-eee relative"
            style="width: 214px; height: 160px"
          >
            <img
              class="w-full h-full object-contain"
              v-if="current.targetPhoto"
              :src="current.targetPhoto.url"
              alt=""
            />
            <div
              class="absolute bottom-0 right-0 text-xs bg-black text-white"
              style="padding: 5px 8px 5px 12px"
            >
              {{ current.targetTypeName }}
            </div>
            <div
              class="
                absolute
                top-20
                left-0
                bg-primary
                text-xs text-white
                rounded-tr-full rounded-br-full
              "
              style="padding: 5px 10px"
            >
              {{ current.statusName }}
            </div>
          </div>
          <div style="width: calc(100% - 234px)">
            <div class="font-bold ellipsis-2 mb-20">
              {{ current.targetName }}
            </div>
            <div class="ellipsis-3 text-666 text-sm mb-20">
              {{ current.targetDesc }}
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-between mt-40 text-sm">
        <div class="flex items-end">
          <div style="width:24px;height:24px" class="rounded-full bg-white mr-10">
            <img v-if="current.userPhoto" :src="current.userPhoto.url" alt="">
          </div>
          <span>{{current.userName}}</span>
        </div>
        <div>{{current.createTime}}</div>
      </div>
      <div class="mt-10 text-lg font-bold">
        {{ current.title }}
      </div>
      <div class="text-right mt-20" v-if="role !== 2">
        <span class="text-xs text-999">提问是否对外展示 </span>
        <a-switch
          v-model="current.visible"
          @change="setCommentVisible(current.id, $event)"
        ></a-switch>
      </div>
      <div class="mt-40">
        <div class="font-blod">
          全部回复（{{ current.answers ? current.answers.length : 0 }}）
        </div>
        <div class="text-sm text-999">
          <div v-if="current.answers && current.answers.length">
            <div class="mt-20" v-for="item in current.answers" :key="item.id">
              <div class="flex border-b border-eee">
                <div
                  class="
                    mr-20
                    border border-eee
                    relative
                    rounded-full rounded-br-none
                    overflow-hidden
                  "
                  style="width: 24px; height: 24px"
                >
                
                  <img
                    class="w-full h-full object-contain"
                    v-if="item.userLogo"
                    :src="item.userLogo.url"
                    alt=""
                  />
                </div>
                <div style="width: calc(100% - 48px)" class="relative">
                  <div class="ellipsis-2 mb-10 text-xs">
                    <span class="text-primary mr-10">{{ item.userName }}</span>
                    <span class="text-999">{{ item.createTime }}</span>
                  </div>
                  <div class="mb-20 text-sm">
                    {{ item.content }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-20" v-else>暂未收到专家回复</div>
        </div>
        <div v-if="role === 2 && !current.myAnswer">
          <div class="mt-40">
            <a-form-model>
              <gf-textarea
                v-focus
                :rows="6"
                :max="300"
                placeholder="请在此输入您的回复"
                v-model="model.Content"
              ></gf-textarea>
            </a-form-model>
          </div>
          <div class="text-right mt-20">
            <a-button type="primary" @click="answerComment(current.id)"
              >确定</a-button
            >
          </div>
        </div>

        <div class="text-center mt-40" v-else>
          <a-space>
            <a-button @click="visible = false" type="primary" ghost
              >取消</a-button
            >

            <a-button type="danger" @click="delComment(current)">删除</a-button>
          </a-space>
        </div>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import {
  getComments,
  delComment,
  setCommentVisible,
  answerComment,
  delAnswer,
} from "@/api/comment.js";
export default {
  data() {
    return {
      comments: [],
      total: 0,
      page: 1,
      limit: 5,
      filter: "all",
      visible: false,
      current: {},
      inputing: false,
      model: {},
    };
  },
  computed: {
    role() {
      return this.$store.state.role;
    },
    id() {
      return this.$store.state.userInfo.id;
    },
  },
  created() {
    this.jumpPage(1);
  },
  methods: {
    getComments(params) {
      getComments(params).then(({ rows, count }) => {
        this.comments = rows.map((item) => {
          const temp = { ...item };

          temp.myAnswer = temp.answers.find(
            (answer) => answer.userId === this.id
          );
          return temp;
        });
        this.total = count;
      });
    },
    jumpPage(e) {
      const params = {
        offset: (e - 1) * this.limit,
      };
      params.filter = this.filter;
      params.limit = this.limit;
      this.model = {};
      this.getComments(params);
    },
    delComment(comment) {
      if (this.role === 2) {
        delAnswer(comment.id, comment.myAnswer.id)
          .then(() => {
            this.$message.success("删除回答成功");
            this.visible = false;
            this.jumpPage(this.page);
          })
          .catch(() => {
            this.$message.error("删除回答失败");
          });
      } else {
        delComment(comment.id)
          .then(() => {
            this.$message.success("删除提问成功");
            this.visible = false;
            this.jumpPage(this.page);
          })
          .catch(() => {
            this.$message.error("删除提问失败");
          });
      }
    },
    open(data) {
      this.$set(data, "inputing", false);
      this.visible = true;
      this.current = data;
    },
    setCommentVisible(id, visible) {
      setCommentVisible(id, { public: visible }).then(() => {
        this.$message.success("设置可见性成功");
      });
    },
    answerComment(id) {
      if (!this.model.Content) {
        return this.$message.error("请输入您的回复");
      }
      answerComment(id, this.model).then(() => {
        this.$message.success("回复成功");
        this.visible = false;
        this.jumpPage(this.page);
      });
    },
  },
};
</script>