import { request, proRequest } from '@/utils/request.js'
import { transformComment } from '@/utils/transform';

// 删除评论
export function delComment(id) {
    return request.delete(`/comments/${id}`)
}

// 更新提问
export function updateComment(id, data) {
    return request.put(`/comments/${id}`, data)
}

// 回答提问
export function answerComment(id, data) {
    return request.post(`/comments/${id}/answers`, data)
}

// 删除提问回答
export function delAnswer(id, a_id) {
    return request.delete(`/comments/${id}/answers/${a_id}`)
}

// 提问提交审核
export function submitComment(id, data) {
    return request.post(`/comments/${id}/submit`, data)
}

// 设置提问可见性
export function setCommentVisible(id, data) {
    return request.post(`/comments/${id}/visible`, data)
}

// 后台获取提问审核列表
export function getProComments(params) {
    return proRequest.get(`/comments`, { params }).then(({ data }) => {
        let temp = {}, rows;
        rows = data.rows || [];

        temp.count = data.count;
        temp.rows = rows.map(row => transformComment(row))
        return Promise.resolve(temp)
    })
}

// 后台保存提问信息
export function updateProComment(id, data) {
    return proRequest.put(`/comments/${id}`, data)
}

// 后台提问审核
export function evaluateProComment(id, data) {
    return proRequest.post(`/comments/${id}/audit`, data)
}

// 获取用户提问列表
export function getComments(params) {
    return request.get('/user/comments', { params }).then(({ data }) => {
        let temp = {}, rows;
        rows = data.rows || [];

        temp.count = data.count;
        temp.rows = rows.map(row => transformComment(row))
        return Promise.resolve(temp)
    })
}